import React, {useContext, useEffect, useState} from 'react'
import {useApi} from "./ApiContext";
import {Backdrop, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import loadingImage from './../assets/images/loading_white.svg'

const AppStateContext = React.createContext({})

export const useAppState = () => {
    return useContext(AppStateContext)
}
const defaultUserState = {
    vid: null,
    name: null,
    access: false
}
export function AppStateProvider({children}) {

    const resetUser = () => {
        setUser(defaultUserState)
        localStorage.removeItem('userData')
    }
    const [user, setUser] = useState(
        {...defaultUserState, ...JSON.parse(localStorage.getItem('userData'))}
    )

    const hasLoggedIn = !!user?.vid
    const access = hasLoggedIn && user?.access === true

    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en')

    useEffect(()=>{
        if (localStorage.getItem('language') !== language)
            localStorage.setItem('language', language)
    },[language])
    useEffect(()=>{
        localStorage.setItem('userData', JSON.stringify(user))
    }, [user])

    return <AppStateContext.Provider value={{user, setUser, language, setLanguage, resetUser, hasAccess: access, hasLoggedIn}}>
        {children}
    </AppStateContext.Provider>
}