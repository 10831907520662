import styles from './RunwayCenterlineDraw.module.css'
import {findPointByRadialAndDistance} from "../../helpers/geo";
import {toFixed} from "ol/math";

export default function RunwayCenterlineDraw() {

    const loadValues = () => {
        return {
            icao: document.getElementById('inp-icao').value,
            rwy: {
                ident: document.getElementById('inp-rwy').value,
                threshold_lonlat: [
                    parseFloat(document.getElementById('inp-thr_lon').value),
                    parseFloat(document.getElementById('inp-thr_lat').value)
                ],
                rwy_track: parseFloat(document.getElementById('inp-track').value)
            },
            rcl: {
                total_dist: parseFloat(document.getElementById('inp-length').value),
                tick_dist: {
                    minor: parseFloat(document.getElementById('inp-mintick').value),
                    major: parseFloat(document.getElementById('inp-majtick').value)
                },
                customTick: document.getElementById('inp-customtick').value.replace(' ','').split(',').filter(x=>x.length).map(x=>x.split(':')).map(([dist, width])=>({dist, width}))
            }
        }
    }

    const process = () => {
        const data = loadValues()
        const AURORA_HEADER = `${data.icao};${data.rwy.ident};RCL;-90;-180;`
        const [start_lon, start_lat] = data.rwy.threshold_lonlat
        const revRadial = (360 - data.rwy.rwy_track + 180) % 360
        const radPlus90 = (revRadial + 90) % 360
        const radMinus90 = (revRadial + 270) % 360
        const [end_lon, end_lat] = findPointByRadialAndDistance([start_lon, start_lat], revRadial, data.rcl.total_dist)
        let ticks = []
        let AURORA_OUT = `${AURORA_HEADER}\n// CENTERLINE - ${toFixed(data.rcl.total_dist, 1)} nm\n`
        AURORA_OUT += `${toFixed(start_lat, 5)};${toFixed(start_lon, 5)};;\n`
        AURORA_OUT += `${toFixed(end_lat, 5)};${toFixed(end_lon, 5)};;\n`

        if (data.rcl.tick_dist.major && data.rcl.tick_dist.minor) {
            let i = data.rcl.tick_dist.minor;
            while (data.rcl.tick_dist.minor > 0 && i <= data.rcl.total_dist) {
                if(i % data.rcl.tick_dist.major === 0) {i+=data.rcl.tick_dist.minor; continue}
                const point = findPointByRadialAndDistance([start_lon, start_lat], revRadial, i)
                const [s_lon, s_lat] = findPointByRadialAndDistance(point, radPlus90, 0.5)
                const [e_lon, e_lat] = findPointByRadialAndDistance(point, radMinus90, 0.5)
                AURORA_OUT += `// MINOR TICK - ${toFixed(i, 2)} nm\n`
                AURORA_OUT += `${toFixed(s_lat, 5)};${toFixed(s_lon, 5)};<br>;\n`
                AURORA_OUT += `${toFixed(e_lat, 5)};${toFixed(e_lon, 5)};;\n`
                i+= data.rcl.tick_dist.minor
            }
            i = data.rcl.tick_dist.major;
            while (data.rcl.tick_dist.major > 0 && i <= data.rcl.total_dist) {
                const point = findPointByRadialAndDistance([start_lon, start_lat], revRadial, i)
                const [s_lon, s_lat] = findPointByRadialAndDistance(point, radPlus90, 1)
                const [e_lon, e_lat] = findPointByRadialAndDistance(point, radMinus90, 1)
                AURORA_OUT += `// MAJOR TICK - ${toFixed(i, 2)} nm\n`
                AURORA_OUT += `${toFixed(s_lat, 5)};${toFixed(s_lon, 5)};<br>;\n`
                AURORA_OUT += `${toFixed(e_lat, 5)};${toFixed(e_lon, 5)};;\n`
                i+= data.rcl.tick_dist.major
            }
        }
        else {
            for(const x of data.rcl.customTick) {
                const point = findPointByRadialAndDistance([start_lon, start_lat], revRadial, x.dist)
                const [s_lon, s_lat] = findPointByRadialAndDistance(point, radPlus90, x.width / 2)
                const [e_lon, e_lat] = findPointByRadialAndDistance(point, radMinus90, x.width / 2)
                AURORA_OUT += `// CUSTOM TICK - ${toFixed(x.dist, 2)} nm, width: ${toFixed(x.width, 2)} nm\n`
                AURORA_OUT += `${toFixed(s_lat, 5)};${toFixed(s_lon, 5)};<br>;\n`
                AURORA_OUT += `${toFixed(e_lat, 5)};${toFixed(e_lon, 5)};;\n`
            }

        }

        document.getElementById('out').innerText = AURORA_OUT
    }

    return <div className={styles.main} style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{textAlign: 'left'}}>
            <p>Airport ICAO: <input type={'text'} maxLength={4} minLength={4} placeholder={'EDDH'} id={'inp-icao'}/></p>
            <p>Runway identifier: <input type={'text'} placeholder={'08C'} maxLength={3} minLength={2} id={'inp-rwy'}/></p>
            <p>Runway threshold LAT: <input step={0.00001} defaultValue={0.00000} min={-90} max={90} type={'number'} id={'inp-thr_lat'}/></p>
            <p>Runway threshold LON: <input step={0.00001} defaultValue={0.00000} min={-180} max={180} type={'number'} id={'inp-thr_lon'}/></p>
            <p>Runway track (true): <input step={0.1} defaultValue={0.00} min={0} max={360} type={'number'} id={'inp-track'}/></p>
            <p>Total centerline distance (nm): <input step={0.5} defaultValue={10} min={0} max={20} type={'number'} id={'inp-length'}/></p>
            <p>Minor tick (width 0.5nm) every ? nm: <input step={0.5} defaultValue={1} min={0} max={10} type={'number'} id={'inp-mintick'}/><br/>Set 0 for none or custom ticks</p>
            <p>Major tick (width 1nm) every ? nm: <input step={0.5} defaultValue={5} min={0} max={10} type={'number'} id={'inp-majtick'}/><br/>Set 0 for none or custom ticks</p>
            <p>Custom ticks: <input type={'text'} id={'inp-customtick'} placeholder={'5:1,10:1,15:1,20:1'}/>
                <br/>
                (<code>nm_from_thr:tickwidth_nm,nm_from_thr:tickwidth_nm...</code>)
            </p>
            <p><button onClick={()=>process()}>DO</button></p>
        </div>
        <div id={'out'} style={{textAlign: 'left', fontFamily: 'monospace'}}></div>
    </div>
}