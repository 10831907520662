import React, {useContext, useEffect, useState} from 'react'
import $ from "jquery";
import {useAppState} from "./AppStateContext";
import {useNavigate, useLocation} from "react-router-dom";
import {Backdrop, Button, Grid, Typography} from "@mui/material";
import loadingImage from "../assets/images/loading_white.svg";

const ApiContext = React.createContext({})

export const useApi = () => {
    return useContext(ApiContext)
}
const defaultState = {
    accessToken: null,
    expiredSession: false
}
export function ApiProvider({children}) {

    // TODO: Remove API state from LS (insecurity of frontend functions)

    const {resetUser, language} = useAppState()

    const navigate = useNavigate()
    const location = useLocation()
    const resetApp = () => {
        navigate('/')
        resetApi()
        resetUser()
    }

    const [loading, setLoading] = useState(false)

    const [state, setState] = useState(
        {...defaultState, ...JSON.parse(localStorage.getItem('apiState'))}
    )

    const defaultFailCallback = (result) => {
        // eslint-disable-next-line eqeqeq
        if(result.status === 401) {
            //TODO: Callback on no access
            setState({...state, expiredSession: true})
        }
        else if (403 === result.status) {
            navigate(`/forbidden?from=${location.pathname}`)
        }
        else {
            console.error('Request failed. Status: '+result.status)
        }
    }

    const getApiUrl = (apipath, v2 = false) => {
        // return (!v2?'https://api.xr.ivao.aero/'+apipath:'http://localhost.ivao.aero:310/' + apipath.replace(/^\//g, ''));
        return `https://${v2 ? 'api.v2.' : 'api.'}xr.ivao.aero/` + apipath.replace(/^\//g, '');
    }
    const resetApi = () => {
        setState(defaultState)
        localStorage.removeItem('apiState')
    }

    const apiCall = ({params = {}, forceToken = null, path, onDone, onFail = defaultFailCallback, async = true, method = 'POST', authorized = true, shouldAffectLoadingState = false, v2 = false, simple = false}) => {
        const headers = (authorized)?{
            Authorization: !forceToken?("Bearer " + state.accessToken):("Bearer "+forceToken)
        }:{};
        if (shouldAffectLoadingState) setLoading(true)
        $.ajax({
            async: async,
            url: getApiUrl(path, v2),
            data: method === 'GET'? params: JSON.stringify(params),
            contentType: simple?undefined:'application/json',
            method: method,
            headers: simple?undefined:headers
        }).done((res)=>{onDone(res);setLoadingWithTimeout(false);}).fail(onFail);
    }

    const forceLogin = () => {
        setState({...state, accessToken: null, expiredSession: false})
        localStorage.setItem('apiState', JSON.stringify({...state, accessToken: null, expiredSession: false}))
        // TODO: Set to true in production mode
        let href = window.location.href;
        const force_https = false;
        if(force_https) {
            // TODO: Will look better with regex
            href = href.replace('https','{PROTOCOL}').replace('http', '{PROTOCOL}').replace('{PROTOCOL}', 'https')
        }
        window.location.href = 'https://login.ivao.aero/?url='+encodeURI(href)
    }

    //TODO: А вот надо ли оно???
    const setLoadingWithTimeout = (val) => {
        if(val === false) setTimeout(()=>{setLoading(val)}, 1000)
        else {setLoading(val)}
    }


    const setAccessToken = (token) => {
        setState({...state, accessToken: token, expiredSession: false})
    }

    useEffect(()=>{
        localStorage.setItem('apiState', JSON.stringify(state))
    }, [state])


    return <ApiContext.Provider value={{apiState: state, apiCall, resetApi, setAccessToken, login: forceLogin, resetApp, setLoading: setLoadingWithTimeout, isLoading: loading}}>
        {/*<Backdrop open={state.expiredSession && (state.accessToken || location.pathname !== '/')} style={{zIndex:2001}} >*/}
        {/*    <Grid container direction={"column"} style={{height:'100%', userSelect:'none'}}>*/}
        {/*        <Grid item xs/>*/}
        {/*        <Grid item xs={3} style={{justifyContent: 'center', display: 'flex', backgroundImage: `url(${loadingImage})`, backgroundPositionX: '50%', backgroundRepeat: 'no-repeat'}}>*/}
        {/*            /!*<img src={loadingImage} style={{maxHeight:'100%'}}/>*!/*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={2} style={{display:'flex',alignItems:'center', justifyContent:'center'}}>*/}
        {/*            <Typography align={'center'} variant={'h2'} fontWeight={'bold'} color={'secondary'}>*/}
        {/*                {language === 'ru' && 'ВАША СЕССИЯ ИСТЕКЛА'}*/}
        {/*                {language === 'en' && 'YOUR SESSION HAS EXPIRED'}*/}
        {/*            </Typography>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={1} style={{display:'flex',alignItems:'center', justifyContent:'center'}}>*/}
        {/*            <Button onClick={()=>{forceLogin()}} variant={'contained'}>*/}
        {/*                {language === 'ru' && 'ОБНОВИТЬ СЕССИЮ'}*/}
        {/*                {language === 'en' && 'REFRESH SESSION'}*/}
        {/*            </Button>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs/>*/}
        {/*    </Grid>*/}
        {/*</Backdrop>*/}
        {/*<Backdrop open={!state.expiredSession && loading} style={{zIndex:2000}}>*/}
        {/*    <Grid container direction={"column"} style={{height:'100%', userSelect:'none'}}>*/}
        {/*        <Grid item xs/>*/}
        {/*        <Grid item xs={3} style={{justifyContent: 'center', display: 'flex', backgroundImage: `url(${loadingImage})`, backgroundPositionX: '50%', backgroundRepeat: 'no-repeat'}}>*/}
        {/*            /!*<img src={loadingImage} style={{maxHeight:'100%'}}/>*!/*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={2} style={{display:'flex',alignItems:'center', justifyContent:'center'}}>*/}
        {/*            <Typography align={'center'} variant={'h2'} fontWeight={'bold'} color={'secondary'}>*/}
        {/*                {language === 'ru' && 'ЗАГРУЗКА'}*/}
        {/*                {language === 'en' && 'LOADING'}*/}
        {/*            </Typography>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs/>*/}
        {/*    </Grid>*/}
        {/*</Backdrop>*/}
        {children}
    </ApiContext.Provider>
}