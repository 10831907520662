const {sin, asin, cos, sqrt, atan2, PI: pi} = Math

function degToRad(degrees) {
    return (pi/180)*degrees
}

function radToDeg(radians) {
    return (180/pi)*radians
}

function nmToRad(nmDist) {
    return (pi/(180*60))*nmDist
}

function radToNm(radDist) {
    return radDist*((180*60)/pi)
}

export const distance = (a, b) => {
    a.lat = (Math.PI/180)*a.lat
    b.lat = (Math.PI/180)*b.lat
    a.lon = (Math.PI/180)*a.lon
    b.lon = (Math.PI/180)*b.lon
    return ((180*60)/Math.PI)*2*Math.asin(
        Math.sqrt(
            (
                Math.sin((a.lat-b.lat)/2))**2
            +
            Math.cos(a.lat)*Math.cos(b.lat)*(Math.sin((a.lon-b.lon)/2))**2
        )
    )
}



export function findPointByRadialAndDistance(startingPointLonLat, radialDegrees , distanceNm = null, distanceDegrees = null) {
    if (distanceNm === null && distanceDegrees === null) throw Error('A distance is required!')
    // @ts-ignore
    const d = distanceNm ? nmToRad(distanceNm) : degToRad(distanceDegrees)
    const [lon1, lat1] = startingPointLonLat.map(x=>degToRad(x))
    const tc = degToRad(radialDegrees)

    const lat =asin(sin(lat1)*cos(d)+cos(lat1)*sin(d)*cos(tc))
    const dlon=atan2(sin(tc)*sin(d)*cos(lat1),cos(d)-sin(lat1)*sin(lat))
    const lon = ( lon1 - dlon + pi ) % (2*pi) - pi

    return [lon, lat].map(radToDeg)
}