import logo from './logo.svg';
import './App.css';
import {AppStateProvider} from "./contexts/AppStateContext";
import {ApiProvider} from "./contexts/ApiContext";
import VrpMap from "./features/mvl-vrp/VrpMap";
import {Route, Routes} from "react-router-dom";
import RunwayCenterlineDraw from "./features/sectorDev/RunwayCenterlineDraw";

function App() {
  return (
      <AppStateProvider>
        <ApiProvider>
          <div className="App">
              <Routes>
                  <Route path={'/mvl'} element={<VrpMap/>}/>
                  <Route path={'/sectorDev/aurora-rcl'} element={<RunwayCenterlineDraw/>}/>
              </Routes>
          </div>
        </ApiProvider>
      </AppStateProvider>
  );
}

export default App;
