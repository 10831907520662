import React, {useEffect, useRef, useState} from "react";
import VectorSource from "ol/source/Vector";
import 'ol/ol.css'
import {LineString, Point} from "ol/geom";
import {fromLonLat} from "ol/proj";
import {Feature, Map, View} from "ol";
import {OSM} from "ol/source";
import VectorLayer from "ol/layer/Vector";
import TileLayer from "ol/layer/Tile";
import {useApi} from "../../contexts/ApiContext";
import {Circle, Fill, Stroke, Style, Text, RegularShape} from "ol/style";
import Graph from "graphology";
import {dijkstra} from "graphology-shortest-path";
import styles from './VrpMap.module.css'
import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    Grid, IconButton,
    Input,
    ListItem, ListItemText,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import $ from 'jquery'
import {AltRoute, Directions} from "@mui/icons-material";

let map = null

let graph = null

const style = new Style({
    stroke: new Stroke({
        width: 2,
        color: 'rgba(0,0,0,0.5)'
    })
});

const style_text = new Style({
    stroke: new Stroke({
        width: 2,
        color: 'rgba(0,0,0,0.7)'
    }),
    text: new Text({
        font: '0.6rem "Open Sans", "Arial Unicode MS", "sans-serif"',
        placement: 'line',
        fill: new Fill({color: '#333'}),
        stroke: new Stroke({color: 'rgba(255,255,255,0.7)', width: 1}),
        text: (f)=>f.getProperty('text'),
        offsetY: -7,
    })
});

const afStyle = new Style({
    image: new Circle({
        radius: 5,
        stroke: new Stroke({
            color: 'green',
            width: 1,
        }),
        fill: new Fill({
            color: 'white'
        })
    }),
    text: new Text({
        font: '0.6rem "Open Sans", "Arial Unicode MS", "sans-serif"',
        placement: 'line',
        fill: new Fill({color: '#333'}),
        stroke: new Stroke({color: 'rgba(255,255,255,0.7)', width: 1}),
        text: (f)=>f.getProperty('text'),
        offsetY: -7,
    })
})

const style_point = new Style({
    image: new RegularShape({
        points: 3,
        radius: 5,
        stroke: new Stroke({
            color: 'black',
            width: 1,
        }),
        fill: new Fill({
            color: 'white'
        })
    }),
    text: new Text({
        font: '0.6rem "Open Sans", "Arial Unicode MS", "sans-serif"',
        placement: 'line',
        fill: new Fill({color: '#333'}),
        stroke: new Stroke({color: 'rgba(255,255,255,0.7)', width: 1}),
        text: (f)=>f.getProperty('text'),
        offsetY: -7,
    })
})

const style_point_notext = new Style({
    image: new RegularShape({
        points: 3,
        radius: 5,
        stroke: new Stroke({
            color: 'black',
            width: 1,
        }),
        fill: new Fill({
            color: 'white'
        })
    })
})

const style_rte = new Style({
    stroke: new Stroke({
        width: 4,
        color: '#f00'
    }),
})

const lineSource = new VectorSource()
const pointSource = new VectorSource()
const routeSource = new VectorSource()
const airfieldsSource = new VectorSource()

const optsFilter = createFilterOptions({
    matchFrom: "any",
    limit: 100,
    stringify: (o) => o.name,
})

const getShortestPath = (from, to) => {
    console.log(from, to)
    const path = dijkstra.bidirectional(graph, from, to, 'weight')
    console.log(path)
    const s = path.slice(1, path.length-1)
    return {
        route: path.slice(0, path.length-2).reduce((prev, cur, i)=>{
            // console.log(cur, s[i])
            console.log(cur, graph.getNodeAttribute(cur, 'name'), graph.getNodeAttribute(cur, 'pos'))
            const rte = graph.getEdgeAttribute(graph.edges(cur, s[i])[0], 'route')
            console.log(cur, s[i])
            console.log(graph.getEdgeAttribute(graph.edges(cur, s[i])[0], 'route'))
            cur = graph.getNodeAttribute(cur, 'name')
            if(i === 0) return `${cur} ${rte}`
            else if(prev.slice(prev.length - rte.length, prev.length) !== rte) {
                return `${prev} ${cur} ${rte}`
            }
            else return prev
        }, '')+' '+graph.getNodeAttribute(path[path.length-1], 'name'),
        distance: path.slice(0, path.length-2).reduce((prev, cur, i)=> {
            return prev + graph.getEdgeAttribute(graph.edges(cur, s[i])[0], 'weight')
        }, 0),
        geo: path.map((x)=>{
            return graph.getNodeAttribute(x, 'pos')
        })
    }
}

const prefix_repl = {N: 'E', S: 'W'}
const zeroPad = (num, places) => String(num).padStart(places, '0')
const formatCoord = (coord, isEastWest = false) => {
    let prefix = 'N'
    coord < 0 && (prefix = 'S')
    isEastWest && (prefix = prefix_repl[prefix])
    const d = Math.floor(coord)
    const m = Math.floor((coord-d)*60)
    const s = Math.floor((coord - d - m/60)*3600)
    return `${prefix}${zeroPad(d, isEastWest?3:2)}°${zeroPad(m, 2)}'${zeroPad(s, 2)}''`

}

const formatCoords = (coords) => {
    return `${formatCoord(coords[1])} ${formatCoord(coords[0], true)}`
}

export default function VrpMap() {

    const mapRef = useRef()

    const {apiCall} = useApi()

    const [data, setData] = useState(null)

    const [selection, setSelection] = useState([{
        from: null,
        to: null
    }])

    const [route, setRoute] = useState(null)


    useEffect(()=>{
        apiCall({
            v2: true,
            simple: true,
            path: 'static/tools/vrp-mvl.json',
            method: 'GET',
            onDone: (res) => {
                setData(res)
            }
        })
    },[])

    useEffect(()=>{
        if(data) {
            graph = new Graph({
                type: 'directed',
                multi: true
            })
            pointSource.clear()
            lineSource.clear()
            routeSource.clear()
            for(const point of data.points) {
                pointSource.addFeature(new Feature({
                    id: point.id,
                    text: point.name,
                    name: point.name,
                    geometry: new Point(fromLonLat([point.pos.lon, point.pos.lat]))
                }))
                if(!graph.hasNode(point.id))
                    graph.addNode(point.id, {name: point.name, pos: point.pos})
            }
            for(const rte of data.routes) {
                lineSource.addFeature(new Feature({
                    id: rte.route_id,
                    text: rte.route_id,
                    geometry: new LineString(rte.geo.map((x)=>fromLonLat([x.lon, x.lat])))
                }))
                console.log(`${rte.route_id}_${rte.to}_${rte.from}`)
                graph.addEdgeWithKey(`${rte.route_id}_${rte.from}_${rte.to}`, rte.from, rte.to, {weight: parseFloat(rte.distance), route: rte.route_id})
                if(rte.dir[0].match(/Двух?сторонняя/)) {
                    graph.addEdgeWithKey(`${rte.route_id}_${rte.to}_${rte.from}`, rte.to, rte.from, {weight: parseFloat(rte.distance), route: rte.route_id})
                }
            }
            for(const lp of data.landing_sites) {
                airfieldsSource.addFeature(new Feature({
                    id: 'lp_'+lp.id,
                    name: lp.name,
                    text: lp.id,
                    geometry: new Point(fromLonLat([lp.coords.lon, lp.coords.lat]))
                }))
            }
        }
    },[data])

    useEffect(()=>{
        if(!map)
            map = new Map({
                view: new View({
                    center: fromLonLat([60, 30]),
                    zoom: 2
                }),
                target: mapRef.current,
                layers: [
                    new TileLayer({source: new OSM(), zIndex: 0}),
                    new VectorLayer({source: lineSource,
                        style: style,
                        zIndex: 1,
                        maxZoom: 7
                    }),
                    new VectorLayer({source: airfieldsSource,
                        style: (f, res) => {
                            afStyle.getText().setText(f.get('text'))
                            return afStyle
                        },
                        zIndex: 0,
                        minZoom: 7
                    }),
                    new VectorLayer({
                        source: routeSource,
                        style: style_rte,
                        zIndex: 2
                    }),
                    new VectorLayer({source: pointSource,
                        style: (f, res) => {
                            style_point.getText().setText(f.get('text'))
                            return style_point
                        },
                        zIndex: 3,
                        minZoom: 7
                    }),
                    new VectorLayer({source: lineSource,
                        style: (f, res) => {
                            style_text.getText().setText(f.get('text'))
                            return style_text
                        },
                        zIndex: 1,
                        minZoom: 7
                    }),
                    new VectorLayer({source: pointSource,
                        style: style_point_notext,
                        zIndex: 3,
                        maxZoom: 7
                    }),
                ]
            })
    }, [mapRef])

    return <React.Fragment>
        <div id={'map'} style={{height: '100vh', width: '100vw'}} ref={mapRef}/>
        <div id={'container'} style={{position: 'fixed', width: '100vw', left: 0, top: 0}}>
            <Grid container>
                <Grid item xs sm md lg/>
                <Grid item xs={12} sm={8} md={6} lg={4} style={{backgroundColor: 'white', padding: '0 1em 0.5em 1em'}}>
                    <Box display={'flex'}><Autocomplete className={styles.inputPoint} id={'from'} value={selection.from} placeholder={'From'} options={data?.points || []} filterOptions={optsFilter} getOptionLabel={(o)=>o.name} onChange={(e, val)=>{
                        setSelection((sel)=>{return {from: val, to: sel.to}})
                    }} renderInput={(pars) => <TextField {...pars} label={'From'}/>} renderOption={(p, o)=><ListItem {...p} key={'x_from_'+o.id}><ListItemText primary={o.name} secondary={formatCoords([o.pos.lat, o.pos.lon])}/></ListItem>}/>
                    <Autocomplete className={styles.inputPoint} id={'to'} value={selection.to} placeholder={'To'} options={data?.points || []} filterOptions={optsFilter} getOptionLabel={(o)=>o.name} onChange={(e, val)=>{
                        setSelection((sel)=>{return {from: sel.from, to: val}})
                    }} renderInput={(pars) => <TextField {...pars} label={'To'}/>} renderOption={(p, o)=><ListItem {...p} key={'x_to_'+o.id}><ListItemText primary={o.name} secondary={formatCoords([o.pos.lat, o.pos.lon])}/></ListItem>}/>
                    <div className={styles.calcButton}>
                        <IconButton onClick={()=>{
                            routeSource.clear()
                            const rte = getShortestPath(selection.from.id, selection.to.id)
                            if(rte.geo) {
                                routeSource.addFeature(new Feature({
                                    id: 'XX',
                                    geometry: new LineString(rte.geo.map((x)=>fromLonLat([x.lon, x.lat])))
                                }))
                                setRoute(rte)
                            }
                        }}>

                            <Directions/>
                        </IconButton>
                    </div>
                    </Box>
                {route && <div>{route.route} - {(route.distance/1.852).toFixed(2)} nm</div>}
                </Grid>
                <Grid item xs sm md lg/>
            </Grid>
        </div>
    </React.Fragment>
}